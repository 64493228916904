import { render, staticRenderFns } from "./chatMessage.vue?vue&type=template&id=5733bc47&scoped=true"
import script from "./chatMessage.vue?vue&type=script&lang=js"
export * from "./chatMessage.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/chat_css/chatMessage.css?vue&type=style&index=0&id=5733bc47&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5733bc47",
  null
  
)

export default component.exports