import { render, staticRenderFns } from "./chatInitSuport.vue?vue&type=template&id=5a850910&scoped=true"
import script from "./chatInitSuport.vue?vue&type=script&lang=js"
export * from "./chatInitSuport.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/chat_css/chatList.css?vue&type=style&index=0&id=5a850910&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a850910",
  null
  
)

export default component.exports