<template>
  <v-navigation-drawer
    v-model="value"
    @input="$emit('input', $event)"
    :mini-variant.sync="value"
    color="secondary"
    dark
    app
    permanent
  >
    <v-list-item class="px-2 py-1 secondary" dark>
      <v-list-item-avatar>
        <v-img src="/logo.png" contain></v-img>
      </v-list-item-avatar>

      <v-list-item-title class="title">WhireLab</v-list-item-title>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense class="pt-0" nav>
      <template v-for="item in items">
        <v-list-group
          v-if="item.childrens"
          :prepend-icon="item.icon"
          :key="item.title"
          no-action
          active-class="primary"
          class="white--text"
          color="#fff"
        >
          <template v-slot:activator>
            <v-list-item-title class="white--text">{{
              item.title
            }}</v-list-item-title>
          </template>

          <template v-for="child in item.childrens">
            <v-list-group
              v-if="child.childrens"
              :key="child.title"
              no-action
              sub-group
              active-class="primary"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(c, i) in child.childrens"
                :key="i"
                :to="c.url"
                :exact="true"
                link
                active-class="primary"
                class="white--text"
              >
                <v-list-item-title v-text="c.title"></v-list-item-title>

                <!-- <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon> -->
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              :key="child.title"
              :to="child.url"
              :exact="item.exact"
              link
              class="px-3"
              active-class="primary"
              style="width: 95%; margin-left:5%"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="white--text" v-text="child.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item>
            </v-list-item>
          </template>
        </v-list-group>

        <v-list-item
          v-else
          :key="item.title"
          :to="item.url"
          :exact="item.exact"
          link
          active-class="primary"
          class="white--text"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "AppMenu",
  props: {
    value: Boolean,
  },
  data: () => ({
    items_: [
      {
        title: this.$t("test_library"),
        icon: "mdi-clipboard-list",
        url: "/partner/tests",
        exact: true,
      },
      // {
      //   title: this.$t("test_library"),
      //   icon: "mdi-clipboard-list",
      //   route: "/tests",
      //   exact: true,
      //   childrens: [
      //     {
      //       title: this.$t("tests"),
      //       url: "/partner/tests",
      //       icon: "mdi-clipboard-list",
      //     },
      //     {
      //       title: this.$t("activities"),
      //       url: "/partner/test/activities",
      //       icon: "mdi-trending-down",
      //     },
      //   ],
      // },
    ],
    mini: false,
  }),
  computed: {
     items(){
       return [
      //    {
      //   title: this.$t("dashboard"),
      //   icon: "mdi-home",
      //   url: "/partner",
      //   exact: true,
      // },
      //  {
      //   title: this.$t("test_library"),
      //   icon: "mdi-clipboard-list",
      //   url: "/partner/tests",
      //   exact: true,
      // },
      {
        title: this.$t("test_library"),
        icon: "mdi-clipboard-list",
        route: "/tests",
        exact: true,
        childrens: [
          {
            title: this.$t("tests"),
            url: "/partner/tests",
            icon: "mdi-clipboard-list",
          },
          {
            title: this.$t("transactions"),
            url: "/partner/tests/activities",
            icon: "mdi-trending-down",
          },
        ],
      },
       ]
     }
  },
};
</script>
