<template>
  <v-app-bar color="navbar" app>
    <v-app-bar-nav-icon @click.stop="$emit('hide', !show)"></v-app-bar-nav-icon>
    <!-- <v-toolbar-title>{{ title || 'Dashboard' }}</v-toolbar-title> -->

    <v-spacer></v-spacer>
    <div class="px-0">
      <!-- <v-btn icon color="primary" @click="openDialog()">
        <v-icon>info</v-icon>
      </v-btn> -->

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text color v-on="on">
            <flag :iso="currentFlag" v-bind:squared="false" />
            <v-icon right color="primary">mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(entry, index) in languagess"
            :key="`${index}010`"
            @click="changeLocale(entry)"
          >
            <v-list-item-title>
              <flag :iso="entry.flag" v-bind:squared="false" />
              &nbsp;{{ entry.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        offset-y
        v-if="currentUser"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" color="primary">
            <div>
              <v-badge
                color="red"
                :content="notifications"
                bordered
                overlap
                left
                v-if="notifications"
              >
                <v-icon color="primary"> mdi-bell </v-icon>
              </v-badge>
              <v-icon color="primary" v-else> mdi-bell </v-icon>
            </div>
          </v-btn>
        </template>

        <v-card class="mx-auto" max-width="500">
          <v-list two-line>
            <v-list-item-group v-model="selected" active-class="primary--text">
              <template v-for="(item, index) in chats">
                <!-- <pre :key="index">
              {{item.messages}}
            </pre> -->
                <v-skeleton-loader
                  :key="index"
                  type="list-item-avatar"
                  v-if="!loadMessages"
                ></v-skeleton-loader>
                <v-list-item
                  :key="item.name"
                  v-if="
                    (item.flag === 'Suport' && countReads(item.messages) > 0) ||
                    item.flag === 'Chat'
                  "
                  @click="item.flag === 'Chat' ? initChat(item) : initSuport()"
                >
                  <!-- <template> -->

                  <template v-slot:default="{ active }">
                    <v-list-item-avatar>
                      <v-badge
                        bordered
                        bottom
                        color="primary"
                        dot
                        offset-x="15"
                        offset-y="11"
                      >
                        <v-avatar size="35" v-if="item.messages[0].author">
                          <v-img
                            :src="
                              item.messages[0].author.photo
                                ? `${apiUrl}/images/user/${item.messages[0].author.photo}`
                                : '/profile.png'
                            "
                          ></v-img>
                        </v-avatar>
                        <v-avatar size="35" v-else>
                          <v-img
                            :src="
                              item.messages[0].to.photo
                                ? `${apiUrl}/images/user/${item.messages[0].to.photo}`
                                : '/profile.png'
                            "
                          ></v-img>
                        </v-avatar>
                      </v-badge>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          item.flag === 'Suport'
                            ? item.messages[0].to.name === currentUser.name
                              ? 'Suporte Whirelab'
                              : 'Suporte Whirelab'
                            : getUserName(item.messages[0].author.name)
                        "
                      ></v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="item.messages[item.messages.length - 1].body"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-badge
                        color="red"
                        :content="countReads(item.messages)"
                        bordered
                        overlap
                        v-if="countReads(item.messages) > 0"
                      >
                        <v-icon v-if="!active" color="grey"> mdi-chat </v-icon>

                        <v-icon v-else color="grey"> mdi-chat </v-icon>
                      </v-badge>
                      <v-btn block outlined color="primary" v-else>
                        start
                      </v-btn>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider
                  v-if="index < chats.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        offset-y
        v-if="currentUser"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" color="primary">
            <v-avatar size="22" item>
              <img
                v-if="currentUser.photo"
                :src="`${apiUrl}/images/partner/${currentUser.photo}`"
              />
              <v-icon v-else class="primary--text">mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <!-- <pre>{{user}}</pre> -->
              <v-list-item-avatar>
                <img
                  :src="
                    currentUser.photo
                      ? `${apiUrl}/images/partner/${currentUser.photo}`
                      : `/avatar.png`
                  "
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ currentUser.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  {{ currentUser.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item router to="/partner/profile">
              <v-list-item-action>
                <v-icon class="primary--text">mdi-account-circle</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{
                $t("form_candidate_profile")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click.prevent="logout">
              <v-list-item-action>
                <v-icon class="primary--text">mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { API_URL } from "@/api";
import { GC_AUTH_TOKEN, GC_USER_ID } from "@/constants/settings";
import { USER_QUERY } from "./../graphql/Query.resolver";
import { mapActions, mapGetters } from "vuex";
import { CHATS_ROOM_QUERY } from "./../modules/chat/graphql/Query";
import {
  MESSAGE_SENT_SUBSCRIPTION,
  UPDATED_COUNT_CHAT,
} from "./../modules/chat/graphql/subscription";
import { SEND_STATUS } from "./../graphql/Query.resolver";
export default {
  name: "AppToolbar",
  props: {
    show: Boolean,
  },
  model: {
    prop: "show",
    event: "hide",
  },
  data: () => ({
    apiUrl: API_URL,
    currentFlag: "pt",
    showLogoutDialog: false,
    user: null,
    notifications: 0,
    chats: [],
    selected: [2],
    loadMessages: false,
  }),
  apollo: {
    user: {
      query: USER_QUERY,
      fetchPolicy: "no-cache",
    },
    recivedChats: {
      query: CHATS_ROOM_QUERY,
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.recivedChats) {
          this.loadMessages = true;
          console.log("recivedChats toolBar", data.recivedChats);
          // this.chats = data.recivedChats;
          data.recivedChats.forEach((chat) => {
            // console.log('to',chat.messages[chat.messages.length-1]);
            // console.log('author',chat.messages[chat.messages.length-1].author.id);
            if (
              chat.messages[chat.messages.length - 1].to.id !=
              chat.messages[chat.messages.length - 1].author.id
            ) {
              this.chats.push(chat);
            }
          });
          this.chats.forEach((element) => {
            this.notifications += this.countReads(element.messages);
          });
        }
      },
    },
    $subscribe: {
      message: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        result({ data }) {
          console.log("=>>>>>", data.addChat);
          console.log("recp", this.currentUser);
          if (
            data.addChat.messages.to.id === data.addChat.messages.author.id &&
            data.addChat.flag === "Suport"
          ) {
            return;
          } else if (data.addChat.messages.to.id === this.currentUser.id) {
            this.playSound();
            this.getMessage();
          }
        },
      },
      updateCountChat: {
        query: UPDATED_COUNT_CHAT,
        result({ data }) {
          console.log("updated=>>>>>", data.updateCountChat);
          var index = this.chats.findIndex(
            (u) => u.room == data.updateCountChat.room
          );
          if (index > -1) {
            this.chats[index].messages = data.updateCountChat.messages;
            this.notifications = 0;
            this.chats.forEach((element) => {
              this.notifications += this.countReads(element.messages);
            });
          }
        },
      },
    },
  },
  watch: {
    user: function (val) {
      this.setUserState(val);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    languagess: function () {
      return [
        { flag: "gb", language: "en", title: "English" },
        // { flag: "fr", language: "fr", title: "Français" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },
    settings: function () {
      return [
        {
          icon: "mdi-domain",
          title: "Perfil da instituição",
          route: "/dashboard/entity/profile",
          exact: true,
        },
        {
          icon: "mdi-key-variant",
          title: "Nível de Acesso",
          route: "/dashboard/entity/roles",
          exact: true,
        },
        {
          icon: "mdi-account-group",
          title: "Utilizadores",
          route: "/dashboard/entity/users",
          exact: true,
        },
      ];
    },
    userId() {
      return this.$root.$data.userId;
    },
  },
  created() {
    window.addEventListener("beforeunload", this.leaving);
    if (localStorage.getItem("lang") != null) {
      this.$i18n.locale = localStorage.getItem("lang");
      this.currentFlag = localStorage.getItem("flag");
    } else {
      this.$i18n.locale = "pt";
      this.currentFlag = "pt";
    }
  },
  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
    }),
    async playSound() {
      var audio = new Audio("/whirelabRing.wav");
      audio.muted = false;
      audio.autoplay = true;
      audio.load();
      var playPromise = audio.play();
      console.log(playPromise);
      if (playPromise !== undefined) {
        await playPromise
          .then((_) => {
            console.log("play", _);
            audio.play();
          })
          .catch((error) => {
            console.log("err", error);
          });
      }
    },
    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;

      localStorage.setItem("lang", locale.language);
      localStorage.setItem("flag", locale.flag);

      console.log(locale, localStorage.getItem("lang"));
    },
    async loadUser() {
      await this.$apollo.mutate({});
    },
    getUserName(name) {
      name = name.split(" ");
      return name[0];
    },
    logout() {
      this.logoutPropreties();
      this.leaving();
    },
    logoutPropreties() {
      localStorage.removeItem(GC_USER_ID);
      localStorage.removeItem(GC_AUTH_TOKEN);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
      this.$router.push("/login");
    },
    countReads(messages) {
      // console.log(messages);
      if (messages[0].author) {
        return messages.filter(
          (m) => m.read === "1" && m.author.id != this.currentUser.id
        ).length;
      } else {
        return messages.filter(
          (m) => m.read === "1" && m.to.id === this.currentUser.id
        ).length;
      }
    },
    async initChat(valor) {
      // console.log("valor", valor);
      if (valor.messages[0].author) {
        valor = [{ ...valor, to: valor.messages[0].author.id }];
      } else {
        valor = [{ ...valor, to: valor.messages[0].to.id }];
      }

      this.$emit("initChat", valor[0]);
    },
    initSuport() {
      this.$emit("initChatSuport");
    },

    async leaving() {
      await this.$apollo.mutate({
        mutation: SEND_STATUS,
        variables: {
          user_id: this.currentUser.id,
          description: "Offline",
          locale: this.$i18n.locale,
        },
      });
    },
    async backOnline() {
      await this.$apollo.mutate({
        mutation: SEND_STATUS,
        variables: {
          user_id: this.currentUser.id,
          description: "Online",
          locale: this.$i18n.locale,
        },
      });
    },
    async getMessage() {
      await this.$apollo
        .query({
          query: CHATS_ROOM_QUERY,
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          if (res.data.recivedChats) {
            this.chats = res.data.recivedChats;
            this.notifications = 0;
            this.chats.forEach((element) => {
              this.notifications += this.countReads(element.messages);
            });
            console.log("ordered", res.data.recivedChats);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.backOnline();
    }, 1000);
  },
};
</script>
